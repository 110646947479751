// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-page {
    display: flex;
    height: 100vh;
  }
  
  .sidebar {
    background-color: #343a40;
    color: white;
    padding: 20px;
  }
  
  .profile-section {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .profile-section .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  
  .nav-link {
    color: white;
  }
  
  .nav-link:hover {
    background-color: #495057;
  }
  
  .profile-content {
    padding: 20px;
  }
  
  .img-preview {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/css/profileSettings.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,kBAAkB;EACpB","sourcesContent":[".profile-page {\n    display: flex;\n    height: 100vh;\n  }\n  \n  .sidebar {\n    background-color: #343a40;\n    color: white;\n    padding: 20px;\n  }\n  \n  .profile-section {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .profile-section .profile-image {\n    width: 100px;\n    height: 100px;\n    border-radius: 50%;\n  }\n  \n  .nav-link {\n    color: white;\n  }\n  \n  .nav-link:hover {\n    background-color: #495057;\n  }\n  \n  .profile-content {\n    padding: 20px;\n  }\n  \n  .img-preview {\n    width: 100px;\n    height: 100px;\n    border-radius: 50%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
