import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '@css/profileSettings.css'; // Import CSS file for the profile settings
import Sidebar from '@components/common/Sidebar'; // Adjust the import path as needed
import axios from 'axios';
import { useAuth } from '@auth/AuthContext';
import {jwtDecode} from 'jwt-decode'; // Correctly import jwtDecode

const ProfileSettings = () => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [image, setImage] = useState(null);
  const [timezone, setTimeZone] = useState('');
  const [timeZones, setTimeZones] = useState([]); // State to store time zones
  const [phone, setPhone] = useState('');
  const [smsAvailable, setSmsAvailable] = useState('');
  const { token, logout } = useAuth();

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          if (decodedToken.exp < currentTime) {
            logout();
          } else {
            const response = await axios.get(`${window.config.API_BASE_URL}/api/users/info`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            const userData = response.data;
            setName(userData.name);
            setEmail(userData.email);
            setPhone(userData.phone);
            setSmsAvailable(userData.sms_used);
            setTimeZone(userData.timezone); // Set the default timezone from user data
          }
        } catch (error) {
          console.error('Error fetching user info:', error);
          logout();
        }
      }
    };

    fetchUserInfo();

    const fetchTimeZones = async () => {
      try {
        const response = await axios.get(`${window.config.API_BASE_URL}/api/utils/timezones`);
        const timeZonesData = response.data;
        const timeZonesArray = Object.entries(timeZonesData).map(([key, value]) => ({
          key,
          value
        }));
        setTimeZones(timeZonesArray);
      } catch (error) {
        console.error('Error fetching time zones:', error);
      }
    };

    fetchTimeZones();
  }, [token, logout]);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${window.config.API_BASE_URL}/api/users/update`, {
        name,
        email,
        timezone,
        phone,
        image // Assuming the API can handle image URLs, otherwise you may need to handle image uploads separately
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        alert(t('Profile updated successfully'));
        // Optionally, you can update the state with the new user data if needed
      } else {
        alert(t('Failed to update profile'));
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      alert(t('An error occurred while updating the profile. Please try again later.'));
    }
  };

  return (
    <div className="profile-page">
      <Sidebar />
      <Container fluid style={{ marginLeft: '300px' }}> {/* Adjust margin to account for fixed sidebar */}
        <Row>
          <Col>
            <Card className="shadow-sm p-3 mb-5 bg-white profile-content">
              <Card.Body>
                <h2>{t('profile_setting')}</h2>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formName">
                    <Form.Label>{t('name')} *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('name')}
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formEmail" className="mt-3">
                    <Form.Label>{t('email')} *</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={t('email')}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      style={{ color: 'gray' }} // Change email text color to gray
                    />
                  </Form.Group>
                  <Form.Group controlId="formTimeZone" className="mt-3">
                    <Form.Label>{t('timezone')}</Form.Label>
                    <Form.Control
                      as="select"
                      value={timezone}
                      onChange={(e) => setTimeZone(e.target.value)}
                      className="custom-select-dropdown" // Add custom class for styling
                    >
                      {timeZones.map(tz => (
                        <option key={tz.key} value={tz.key}>
                          {tz.key}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formPhone" className="mt-3">
                    <Form.Label>{t('phone')}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t('phone')}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="formSMS" className="mt-3">
                    <Form.Label>{t('SMS_used')}</Form.Label>
                    <Form.Text className="text-muted"> {smsAvailable} used</Form.Text>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="mt-3">
                    {t('save')}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfileSettings;
