import AboutUs from "./AboutUs";
import Booking from "./BookingPage";
import Team from "./TeamPage";
import Testimonial from "./TestimonialPage";
import Contact from "./ContactPage";
import PageNotFound from "./PageNotFound";
import Room from "./RoomPage";
import Services from "./ServicesPage";
import Home from "../components/home/Home";
import Login from "./auth/Login";
import Link from "./Link";
import ProfileSettings from "./ProfileSettings"; // Import the new profile settings page

export {
  Link,
  Login,
  Home,
  Booking,
  Testimonial,
  Team,
  AboutUs,
  Contact,
  PageNotFound,
  Room,
  Services,
  ProfileSettings, // Export the new profile settings page
};
