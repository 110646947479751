import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@auth/AuthContext';
import '@css/login.css';
import {jwtDecode} from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import neariuBackground from '@assets/img/neariub.jpg'; 

const Login = () => {
  const { t } = useTranslation(); // Use translation hook
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.user_id;
        if (userId) {
          navigate(`/link/${userId}`);
        } else {
          console.error('User ID not found in token');
        }
      } catch (error) {
        console.error('Invalid token', error);
      }
    }
  }, [navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${window.config.API_BASE_URL}/api/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        login(data.token);
        if (data.token) {
          try {
            const decodedToken = jwtDecode(data.token);
            const userId = decodedToken.user_id;
            if (userId) {
              navigate(`/link/${userId}`);
            } else {
              console.error('User ID not found in token');
            }
          } catch (error) {
            console.error('Invalid token', error);
          }
        }
      } else {
        setShowModal(true);
      }
    } catch (error) {
      console.log('An error occurred. Please try again later.');
      setShowModal(true);
    }
  };

  const handleClose = () => setShowModal(false);

  return (
    <div className="login-page"  style={{
      backgroundImage: `url(${neariuBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Container>
        <Row className="justify-content-md-center align-items-center" style={{ height: '100vh', width: '100%' }}>
          <Col style={{ flex: '0 0 auto', width: '350px' }}>
            <Card className="shadow-sm p-3 mb-5 bg-white login-content login-content_bg">
              <Card.Body>
                <div className="text-center mb-4">
                  <h1 className="login-title">{t('login')}</h1>
                </div>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>{t('email')}</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={t('email')}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword" className="mt-3 mb-3">
                    <Form.Label>{t('password')}</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t('password')}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Button type="submit" className="btn btn-success btn-block fw-500 mb-3">
                    {t('login')}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Modal */}
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{t('login_error')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{t('email_password_incorrect')}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t('close')}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default Login;
