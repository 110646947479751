// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
    background-color: #343a40;
    color: white;
    padding: 20px;
    width: 300px; /* Increase the width of the sidebar */
    height: 100vh; /* Make sure the sidebar takes full height */
    position: fixed; /* Make the sidebar fixed */
    top: 0;
    left: 0;
    font-size: 14px; /* Adjust font size */
  }
  
  .logo-section {
    text-align: center;
    margin-bottom: 10px;
  }

  .logo-section .logo {
    width: 100px;
    text-align: center;
  }
  
  .profile-section {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .profile-section .logo {
    width: 50px;
    height: 50px;
  }

  .profile-section .name {
    margin-top: 10px;
  }
  
  .profile-section .profile-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
  
  .nav-link {
    color: white;
    display: flex; /* Use flexbox to align icon and text */
    align-items: center;
    margin: 2px 0; /* Add some margin between items */
    font-size: 16px; /* Adjust font size */
  }
  
  .nav-link:hover {
    background-color: #495057;
  }
  
  .mr-2 {
    margin-right: 0.5rem;
  }
  
  .nav.flex-column.ml-3 {
    border-left: 1px solid #495057;
    margin-left: 1rem;
    padding-left: 1rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/css/sidebar.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,YAAY;IACZ,aAAa;IACb,YAAY,EAAE,sCAAsC;IACpD,aAAa,EAAE,4CAA4C;IAC3D,eAAe,EAAE,2BAA2B;IAC5C,MAAM;IACN,OAAO;IACP,eAAe,EAAE,qBAAqB;EACxC;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,aAAa,EAAE,uCAAuC;IACtD,mBAAmB;IACnB,aAAa,EAAE,kCAAkC;IACjD,eAAe,EAAE,qBAAqB;EACxC;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,oBAAoB;EACtB;;EAEA;IACE,8BAA8B;IAC9B,iBAAiB;IACjB,kBAAkB;EACpB","sourcesContent":[".sidebar {\n    background-color: #343a40;\n    color: white;\n    padding: 20px;\n    width: 300px; /* Increase the width of the sidebar */\n    height: 100vh; /* Make sure the sidebar takes full height */\n    position: fixed; /* Make the sidebar fixed */\n    top: 0;\n    left: 0;\n    font-size: 14px; /* Adjust font size */\n  }\n  \n  .logo-section {\n    text-align: center;\n    margin-bottom: 10px;\n  }\n\n  .logo-section .logo {\n    width: 100px;\n    text-align: center;\n  }\n  \n  .profile-section {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .profile-section .logo {\n    width: 50px;\n    height: 50px;\n  }\n\n  .profile-section .name {\n    margin-top: 10px;\n  }\n  \n  .profile-section .profile-image {\n    width: 60px;\n    height: 60px;\n    border-radius: 50%;\n  }\n  \n  .nav-link {\n    color: white;\n    display: flex; /* Use flexbox to align icon and text */\n    align-items: center;\n    margin: 2px 0; /* Add some margin between items */\n    font-size: 16px; /* Adjust font size */\n  }\n  \n  .nav-link:hover {\n    background-color: #495057;\n  }\n  \n  .mr-2 {\n    margin-right: 0.5rem;\n  }\n  \n  .nav.flex-column.ml-3 {\n    border-left: 1px solid #495057;\n    margin-left: 1rem;\n    padding-left: 1rem;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
