import React, { useEffect, useState } from "react";
import { useLocation, Route, Routes } from "react-router-dom";
import "@css/app.css";
import Header from '@components/common/Header';
import Footer from "@components/common/Footer";
import { AuthProvider } from '@auth/AuthContext';
import PrivateRoute from '@auth/PrivateRoute';
import {
  Link,
  Login,
  Home,
  Booking,
  AboutUs,
  Contact,
  PageNotFound,
  Room,
  Services,
  Team,
  Testimonial,
  ProfileSettings // Import the new profile settings page
} from "@pages/index";
import "@config/global";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './i18n'; // Ensure i18n is initialized

const App = () => {
  const location = useLocation();
  const { i18n } = useTranslation(); // Ensure this is called at the top level
  const [isLoading, setIsLoading] = useState(true);

  // List of paths that should not display Header and Footer
  const noHeaderFooterPaths = ["/login", "/link"];

  // Check if the current path is in the list
  const shouldHideHeaderFooter = noHeaderFooterPaths.includes(location.pathname);

  useEffect(() => {
    // Function to load language data from the API or cache
    const loadLanguages = async () => {
      try {
        const cachedLanguages = localStorage.getItem('languages');
        if (cachedLanguages) {
          const languages = JSON.parse(cachedLanguages);
          setTranslations(languages);
          setIsLoading(false); // Set loading to false after loading from cache
        } else {
          const response = await axios.get(`${window.config.API_BASE_URL}/api/languages`);
          const languages = response.data;

          if (Array.isArray(languages) && languages.length > 0) {
            setTranslations(languages);
            localStorage.setItem('languages', JSON.stringify(languages)); // Cache the response
          } else {
            console.error('Invalid language data format');
          }
        }
      } catch (error) {
        console.error('Error loading languages:', error);
      } finally {
        setIsLoading(false); // Set loading to false after attempting to load translations
      }
    };

    const setTranslations = (languages) => {
      const translations = languages.reduce((acc, lang) => {
        acc[lang.phrase] = lang.text;
        return acc;
      }, {});

      i18n.init({
        fallbackLng: 'en',
        interpolation: {
          escapeValue: false, // React already escapes values
        },
        resources: {
          en: {
            translation: translations,
          },
        },
      });
    };

    loadLanguages();
  }, [i18n]);

  if (isLoading) {
    return <div></div>; // Render a loading indicator while fetching translations
  }

  return (
    <div>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<PrivateRoute element={<ProfileSettings />} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/link/:userId" element={<PrivateRoute element={<Link />} />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/team" element={<Team />} />
          <Route path="/testimonial" element={<Testimonial />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/rooms" element={<Room />} />
          <Route path="/services" element={<Services />} />
          <Route path="/profile-settings" element={<PrivateRoute element={<ProfileSettings />} />} /> {/* Add this line */}
        </Routes>
      </AuthProvider>
    </div>
  );
};

export default App;
