import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faPlusCircle, faClipboardList, faHistory, faBan, faCog, faTable, faUserCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import '@css/sidebar.css'; // Assuming you have a CSS file for sidebar styling
import { useAuth } from '@auth/AuthContext'; // Adjust the import path as needed
import axios from 'axios';
import {jwtDecode} from 'jwt-decode'; // Correctly import jwtDecode

const Sidebar = () => {
  const navigate = useNavigate();
  const { token, logout } = useAuth();
  const [profileImage, setProfileImage] = useState('assets/img/user/no-avatar.png'); // Default image
  const [username, setUsername] = useState('NEARIU');

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          if (decodedToken.exp < currentTime) {
            logout();
            navigate('/login');
          } else {
            const response = await axios.get(`${window.config.API_BASE_URL}/api/users/info`, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            const userData = response.data;
            setUsername(userData.name);
            let imageUrl = userData.avatar;
            setProfileImage(`${window.config.NEARIU_URL}/uploads/avatars/${imageUrl}`);
          }
        } catch (error) {
          console.error('Error fetching user info:', error);
          logout();
          navigate('/login');
        }
      }
    };

    fetchUserInfo();
  }, [token, logout, navigate]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="sidebar">
      <div className="logo-section">
        <img src="assets/img/neariu_logo.png" alt="Logo" className="logo" />
      </div>
      <div className="profile-section">
        <img src={profileImage} alt="Profile" className="profile-image" />
        <div className="name">{username}</div>
      </div>
      <nav className="nav flex-column">
        <a className="nav-link" href="#">
          <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" /> Today Reservations
        </a>
        <a className="nav-link" href="#">
          <FontAwesomeIcon icon={faPlusCircle} className="mr-2" /> Add Booking
        </a>
        <a className="nav-link" href="#">
          <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> Reservations
        </a>
        <div className="nav flex-column ml-3">
          <a className="nav-link" href="#">
            <FontAwesomeIcon icon={faClipboardList} className="mr-2" /> All Reservations
          </a>
          <a className="nav-link" href="#">
            <FontAwesomeIcon icon={faHistory} className="mr-2" /> Reservation History
          </a>
          <a className="nav-link" href="#">
            <FontAwesomeIcon icon={faBan} className="mr-2" /> Unavailable Day
          </a>
        </div>
        <a className="nav-link" href="#">
          <FontAwesomeIcon icon={faCog} className="mr-2" /> Setting
        </a>
        <div className="nav flex-column ml-3">
          <a className="nav-link" href="#">
            <FontAwesomeIcon icon={faCog} className="mr-2" /> Reservation Setting
          </a>
          <a className="nav-link" href="#">
            <FontAwesomeIcon icon={faTable} className="mr-2" /> Manage Table
          </a>
        </div>
        <a className="nav-link" href="#">
          <FontAwesomeIcon icon={faUserCircle} className="mr-2" /> My account
        </a>
        <a className="nav-link" onClick={handleLogout} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" /> Logout
        </a>
      </nav>
    </div>
  );
};

export default Sidebar;
