import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import '@css/link.css'; // Import file CSS riêng cho trang đăng nhập
import { useTranslation } from 'react-i18next';
import neariuBackground from '@assets/img/neariub.jpg'; 

const UserLinkPage = () => { // Giả định userId được truyền từ props
  const { userId } = useParams();
  const [selectedUser, setSelectedUser] = useState('');
  const [links, setLinks] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate
  const { t } = useTranslation(); // Use translation hook

  useEffect(() => {
    if (userId) {
      // Fetch links from the API with dynamic userId
      fetch(`${window.config.API_BASE_URL}/api/links/user/${userId}`)
        .then(response => response.json())
        .then(data => {
          setLinks(data);
          if (data.length > 0) {
            setSelectedUser(data[0].link_id); // Set the default selected link
          }
        })
        .catch(error => console.error('Error fetching links:', error));
    }
  }, [userId]); // Chạy lại useEffect khi userId thay đổi

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Selected Link ID:', selectedUser);
    navigate('/'); // Correctly use navigate to navigate to the home page
  };

  return (
    <div className="login-page"  style={{
      backgroundImage: `url(${neariuBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Container>
        <Row className="justify-content-center align-items-center" style={{ height: '100vh', width: '100%' }}>
          <Col style={{ flex: '0 0 auto', width: '350px' }}>
            <Card className="shadow-sm p-3 mb-5 bg-white login-content">
              <Card.Body>
                <div className="text-center mb-4">
                  <h1 className="login-title">{t('neariu')}</h1>
                </div>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="idLinkSelect">
                    <Form.Label className="login-label">{t('select_your_link')}</Form.Label>
                    <Form.Control
                      as="select"
                      value={selectedUser}
                      className="custom-select"
                      onChange={(e) => setSelectedUser(e.target.value)}
                    >
                      {links.map(link => (
                        <option key={link.link_id} value={link.link_id}>
                          {link.title}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Button type="submit" className="btn btn-dark btn-block fw-500 mt-3">
                    {t('proceed')}
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserLinkPage;
